@font-face {
    font-family: Neue;
    src: url('./../fonts/HelveticaNeue.woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: NeueBold;
    src: url('./../fonts/HelveticaNeueBold.woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

*{
    font-family: Neue, san-serif;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

textarea:focus, input:focus{
    outline: none;
}

input, button, submit { border:none; } 

body{
    min-height: 100vh;
}

::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(158, 155, 155, 0.5); 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(183, 183, 183, 0.5); 
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #6EBE49; 
    cursor: pointer !important;
}

a:hover{
    text-decoration: none;
    outline:0 !important;
}
a:link{
    text-decoration: none;
}

.mt-padding.mt-20 {
    padding-top: 0 !important;
    margin-top: 30px;
}
.drawer-opt.pointer {
    cursor: pointer;
}
.loading-fallback{
    height: 100vh !important;
    background: #2C2C2C ;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-fallback-private{
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* LOGIN */
.login-container{
    min-height: 100vh;
}

.login-background-image{
    background-color: #2C2C2C;
    min-height: 100vh; 
    background: url('./../images/BG-Seagate-Central.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center!important;
    /* background-position: 1% 20%!important; */
}

.login-seagate-brand{
    max-width: 75px;
}

.login-seagate-form-container{
    margin-top: 7%;
    margin-left: 8%;
    margin-right: 8%;
    margin-bottom: 5%;
}

.login-seagate-image-container{
    margin-left: 8%;
    margin-right: 8%;
}

.login-seagate-welcome{
    font-family:NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: #2C2C2C;
}

.login-form-eye-icon{
    font-size: 20px;
    color: rgb(119, 116, 116);
    cursor: pointer;
}

.login-forgot-password-link, 
.login-forgot-password-link:hover{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #6EBE49;
}

.login-form-submit-container{
    float: right !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.login-form-submit-text{
    font-family: Neue;
    margin-right: 10px;
    font-size: 15pt;
    margin-bottom: 8px;
    color: #6EBE49;
}

.login-submit-button{
    background: #6EBE49;
    min-height: 60px;
    min-width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 
        0px 3px 5px -1px rgb(0 0 0 / 20%), 
        0px 6px 10px 0px rgb(0 0 0 / 14%), 
        0px 1px 18px 0px rgb(0 0 0 / 12%);
    box-sizing: border-box;
}

.login-submit-button:disabled{
    background: rgb(173, 173, 173);
}

.login-button-icon{
    width: 15px;
    margin-left: 5px;
}

.login-mobile-brand{
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

@media (max-width: 600px){
    .login-form-submit-container{
        /* margin-top: 15vh;
        margin-right: -20px;
         */
         position: fixed;
         bottom: 1vh;
         /* right: 7.5vh; */
         right: 8%;
    }

    .login-mobile-form{
        position: absolute;
        min-height: 35vh;
        width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .welcome-mobile{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15vh;
    }
}
/* LOGIN */

/* LOADING */
.loading-overlay{
    z-index: 99999999999;
    position: fixed;
    top: 0 ;
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.623) !important;
}

.spinner-overlay-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
/* LOADING */

/* APP CONFIG */
.app-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.app-menu{
    width: 15%;
    min-height: 100vh;
    overflow-x: hidden !important;

}

.app-menu-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 15%;
    min-height: 100vh;
    background: linear-gradient(176.67deg, #656565 -3.45%, #404040 35.5%, #2C2C2C 100%), #FFFFFF;

}

.app-container{
    width: 85%;
    min-height: 100vh;
}

@media (max-width: 1920px){
    
    .app-menu .app-menu-container{
        width: 15%;
    }
    
    .app-container{
        width: 85%;
    }
}

@media (max-width: 1600px){
    .app-menu .app-menu-container{
        width: 20%;
    }
    
    .app-container{
        width: 80%;
    }
}

@media (max-width: 1400px){
    .app-menu .app-menu-container{
        width: 25%;
    }
    
    .app-container{
        width: 75%;
    }
}

@media (max-width: 1200px){
    .app-menu .app-menu-container{
        width: 28%;
    }
    
    .app-container{
        width: 72%;
    }
}

.app-menu-brand-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8%;
}

.app-menu-brand-image{
    width: 70%;
}

.menu-avatar{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.left-default-avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
    border-radius: 50%;
    background: #6EBE49;
}

.first-letter{
    color: #FFF;
    font-weight: bold;
    font-size: 20pt;
}

.left-menu-avatar-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.menu-user-link{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 14px;
    color: #6EBE49;
    text-decoration: none;
}

.menu-user-link:hover{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    color: #6EBE49;
    text-decoration: none;
}

.menu-user-name{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
}

.left-menu-user-container{
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.menu-item {
    cursor: pointer;
    margin-right: 7%;
    margin-left: 7%;
    height: 40px;

    display: flex;
    align-items: center;
}

.menu-item-active .menu-text-content {
    color: #6ebe49!important;
}

.menu-text-content {
    display: flex;
    align-items: center;
    font-family: Neue;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #fff;

    transition: 0.3s;
}

.menu-item-active .left-menu-svg-icon path {
    stroke: #6ebe49!important;
}

.menu-item:hover .left-menu-svg-icon path{
    transition: 0.3s;
    stroke: rgba(110, 190, 73, 0.8);
}

.menu-text-content:hover{
    color: #6ebe49!important;
}

.bg-menu-green{
    background: #305534;
}

.text-menu-green{
    color: #56B271;
}

.menu-angle-down{
   margin-left: 12vh;
}

.change-lang-container{
    background: white;
    min-width: 120px;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    font-family: Neue;
}
.change-lang-container .lang-item:not(:first-child){
   margin-top: 10px !important;
}



.app-menu-footer{
    position: absolute;
    left: 0;
    bottom: 0; 
    width: 100%;
}

@media (max-width: 500px){
    .app-menu-footer{
        position: relative !important;
        left: 0;
        bottom: 0; 
        width: 100%;
        margin-top: 22vh !important;
    }

    .margin-mobile-profile{
        margin-top: 5vh !important
    }

    .remember-container{
        margin: 0 !important;
        padding: 0 !important;
        margin-left: -5% !important;
        padding-top: 2% !important;
    }
}
    /* MOBILE */
        .mobile-navbar{
            position: fixed;
            width: 100vw;
            height: 10vh;
            background-color: #6EBE49;
            display: flex;
            justify-content: center;
            align-items: center;

            -webkit-box-shadow: 2px -2px 28px 5px rgba(0,0,0,0.7); 
            box-shadow: 2px -2px 28px 5px rgba(0,0,0,0.7);
            z-index: 9000 !important;
        }

        .mobile-brand{
            width: 60px;
        }

        .mobile-nav{
            position: absolute;
            right: 5% !important;
            width: 30px;
        }

        .mobile-body-container{
            min-height: 100vh;
            padding-top: 10vh;
            overflow-x: hidden;
        }

        .mobile-drawer-container{
            width: 100vw;
            min-height: 100vh;
            background: linear-gradient(176.67deg, #656565 -3.45%, #404040 35.5%, #2C2C2C 100%), #FFFFFF;
            box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.2);
        }

        #spring-popper{
            z-index: 99999999999999 !important;
        }

        .ml-10n{
            padding-left: -100px !important;
        }
    /* MOBILE */

/* APP CONFIG */

/* PROFILE */
.profile-body{
    min-height: 75vh !important;
}

.profile-form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75vh;
    max-width: 550px;
    padding-left: 5rem;
}

.profile-form-control{
    width: 100%;
    /* margin-right: 22%;
    margin-left: 22%; */
}

@media (max-width: 500px){
    .profile-form-container{
        padding-left: 0rem;
    }
    .profile-form-control{
        width: 100%;
        margin-right: 2%;
        margin-left: 2%;
    } 
}

.profile-divider{
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.profile-form-input-container{
    min-height: 5vh;
    display: flex;
    align-items: center;
}

.profile-form-input-container:not(.custom-address) label{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.text-lemon{
    color: #6ebe49;
}

.change-address-wrap {
    row-gap: .8rem;
}

.input-profile{
    background: transparent;
    border: none;
    color: #6ebe49;
    width: 100%;
}

.form-floating .form-control:focus,
.form-floating .form-select:focus {
    outline: none;
    color: #FFF;
    box-shadow: none;
}

.form-floating .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.form-floating .form-select option{
    background-color: rgba(33, 33, 33);
}

.icon-cam {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    transition: all 0.2s ease-out;
    opacity: 0;
    pointer-events: none;
}

.icon-cam > svg {
    fill: rgb(221, 219, 219);
}

.eye-password {
    right: 0;
    top: 0;
    z-index: 10;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 .5rem;
    cursor: pointer;
}

.eye-password svg {
    width: 20px;
    height: 20px;
    fill: #6ebe49;
}

.input-profile:disabled {
    color: hsla(0,0%,100%,.5);
}

.profile-address-input,
.profile-password-input{
    font-family: Neue;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #6ebe49;
    cursor: pointer;
}

.profile-submit-button{
    width: 100%;
    min-height: 40px;
    background: #6EBE49;
    border-radius: 6px;

    font-family: Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    color: #FFFFFF;

    transition: 0.3s;
}

.profile-submit-button:hover{
    background: #528d37;
}

.profile-form-back{
    font-family: Neue;
    color: #6ebe49;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13pt;
}

.profile-submit-button.disabled{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4B4B4B !important;
}

.profile-avatar-image{
    width: 75px;
    height: 75px;
    border-radius: 100%;
}

.profile-avatar-container{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 100%;
    margin-top: 10px;
    cursor: pointer;
}

.profile-avatar-container:hover .icon-cam{
    opacity: 1;
}

.profile-firstLetter{
    width: 75px;
    height: 75px;
    background-color: #6ebe49;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    display: flex;
    align-items: center;
    font-family: Neue;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 20px;
    color: #fff;
}

.profile-upload-label{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6EBE49;
    height: 100%;
    display: flex;
    align-items: center;
}

.label-upload{
    color: #6EBE49 !important;
    font-family: Neue !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
}

.profile-change-address-title,
.profile-change-password-title {
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
}

.crop-container{
    width: 600px !important;
    height: 600px !important;
    overflow-x: hidden;
    overflow-y: hidden;
}

.button-crop-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-crop{
    min-width: 130px;
    background: #6EBE49;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-family: Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 5%;
}

.button-crop:hover{
    background: #487c2f;
}

@media (max-width: 500px){
    .crop-container{
        width: 300px !important;
        height: 300px !important;
        overflow-x: hidden;
    }
}

.modal-title {
    font-family: NeueBold;
    font-weight: 700;
    font-size: 17px;
}
.modal-text, .modal-title {
    font-style: normal;
    line-height: 20px;
    color: #6c757d;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-close-container {
    padding-bottom: 2%;
    padding-top: 1%;
    margin-bottom: 2%;
    border-bottom: 1px solid #dee2e6;
    margin-left: -6%!important;
    margin-right: -6%!important;
}

.modal-body-message, .modal-success-button {
    font-family: Neue;
    font-style: normal;
    font-weight: 400;
    font-size: 14.4px;
    line-height: 20px;
}

.modal-body-message, .modal-success-button {
    font-family: Neue;
    font-style: normal;
    font-weight: 400;
    font-size: 14.4px;
    line-height: 20px;
}

.modal-success-button {
    background: #6ebe49;
    border-radius: 2px;
    min-height: 37px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
    color: #fff;
    border: none;
}
/* PROFILE */

/* HOME */
.visit-slider-title-container {
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 2% !important;
}

.visit-slider-title-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5%;
}

.visit-slider-title {
    font-family: Neue;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 19px;
    color: #2c2c2c;
    text-transform: uppercase;
}

.visit-no-schedule {
    font-family: NeueBold;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: rgba(44,44,44,.5);
    margin-top: 5%;
}

.home-body{
    margin-top: 5%;
}

.home-spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loading-text{
    margin-top: 3%;
    font-family: NeueBold;
}

@keyframes blink {
    50% {
      color: transparent;
    }
}

.loader__dot {
    animation: 1s blink infinite;
}

.loader__dot:nth-child(2) {
    animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
    animation-delay: 500ms;
}

.rec-dot {
    background-color: #C4C4C4 !important;
    width: 8px !important;
    height: 8px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.rec-dot_active {
    background-color: #6EBE49 !important;
    width: 8px !important;
    height: 8px !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.rec-arrow {
    border: none !important;
    background: transparent;
    transition: all 0.3s ease;
    font-size: 1.6em;
    background-color: transparent !important;
    color: #6EBE49 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0% !important;
}

.rec-carousel-item:focus {
    outline: none;
    box-shadow: inset 0 0 1px 1px lightgrey;
}

.options-container{
    margin-top: 10%;
}

.options-item-container{
    height: 180px;
    background: #FFFFFF;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.5s;

}

.options-item-container:hover{
    background: #6EBE49;
}

.options-item-image-container{
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.options-item-image-agendar-visita{
    content: url("./../../assets/images/home-agendar-visita.png");
    width: 45px;
    height: 55px;
}

.options-item-container:hover .options-item-image-agendar-visita{
    content: url("./../../assets/images/home-agendar-visita-w.png");
}

.options-item-image-questionario{
    content: url("./../../assets/images/home-questionario.png");
    width: 53px;
    height: 52px;
}

.options-item-container:hover .options-item-image-questionario{
    content: url("./../../assets/images/home-questionario-w.png");
}

.options-item-image-calendario{
    content: url("./../../assets/images/home-calendario.png");
    width: 60px;
    height: 52px;
}

.options-item-container:hover .options-item-image-calendario{
    content: url("./../../assets/images/home-calendario-w.png");
}

.options-item-container:hover .options-item-title{
    color: #fff;
}

.options-item-title-container{
    height: 10%;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none !important;
}

.options-item-title{
    width: 100%;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 17px;    
  
    color: #000000; 
    text-decoration: none !important;

}

@media (max-width: 500px){
    .visit-slider-container{
        margin-bottom: 10%;
    }

    .rec-arrow-left{
        margin-top: -10% !important;
    }

    .rec-arrow-right{
        margin-top: -10% !important;
    }

    .options-container{
        margin-left: 5%;
        margin-right: 5%;
    } 

    .option-mobile-center{
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
    }

    .options-item-container:hover .options-item-title{
        color: #000;
    }

    .options-item-title{
        white-space: nowrap;
        text-align: center;
    }

    .options-item-container{
        height: 92px;
        width: 92px;
        background: #FFFFFF;
        cursor: pointer;
        transition: 0.5s;
        background: #FFFFFF;
        box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);
        border-radius: 15px;
    }

    .options-item-image-container{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .options-item-title-container{
        display: block;
        align-items: center;
        text-align: center;
        margin-top: 7%;
    }

    .options-item-image-agendar-visita{
        width: 34px;
        height: 43px;
    }

    .options-item-image-calendario{
        width: 46px;
        height: 39px;
    }

    .options-item-image-questionario{
        width: 43px;
        height: 43px;
    }

    .option-mobile-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .options-item-container:hover .options-item-title{
        color: #000;
    }

    .options-item-title{
        white-space: nowrap;
        text-align: center;
    }
}

.mobileGoal{
    background-color: #2C2C2C;
    min-height: 15vh;
}

.goalCircle{
    background-color: #6EBE49;
    height: 11vh;
    width: 11vh;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobileGoalRow{
    min-height: 15vh;
    padding-right: 18px;
}

.goalCircleContainer{
    min-height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.mobileGoalTextContainer{
    margin: 15px;
    margin-left: 12vw;
}

.mobileGoalMeta{
    font-family: NeueBold;
    color: #6EBE49;
    font-size: 15px;
}


.mobileGoalApuracao{
    font-family: Neue;
    color: #fff;
    margin-top: 8px;
    font-size: 10pt;
}

.mobileGoalDate{
    margin-top: 5px;
    font-family: NeueBold;
    font-size: 15pt;
    color: #fff;
}

.mobileGoalDate::first-letter{
    text-transform: uppercase;
}

.goalCircleValue{
    font-family: NeueBold;
    font-size: 30pt;
    color: #fff;
    margin-left: 9px !important;
}

.goalCircleP{
    font-family: NeueBold;
    color: #fff;
    margin-left: 5px !important;
    margin-top: 5px !important;
}

.goals-container{
    height: 128px;
    margin-top: 5% !important;
}

.goals-container-number{
    background: #6EBE49;
    border-radius: 25px 0px 0px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.goals-number-percentage{
    font-family: NeueBold;
    font-style: normal;
    font-size: 30px;
    line-height: 36px;    
    color: #FFFFFF;
    margin-left: 8%;
}

.goals-number{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 72px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.goals-container-body{
    background: #2C2C2C;
    border-radius: 0px 25px 25px 0px;
    height: 100%;
}

.goals-body-image{
    max-width: 56px;
}

.goals-body-image-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.goals-body-container{
    display: flex;
    justify-content: center;
    align-items: left; 
    flex-direction: column;
    margin-left: 5%;
}

.goals-body-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.goals-body-subtitle{
    font-family: Neue ;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 25px;
    color: #FFFFFF;
}

.goals-body-date{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6EBE49;
}

.goals-body-date:first-letter{
    text-transform: uppercase;
}

.bg-seagate-dark{
    background: #2C2C2C;
}

.bg-seagate {
    background: #6EBE49;
}

.bOrange{
    background: #FF5000 !important;
}

.bYellow{
    background: #F8BE15 !important;
}

.bGray{
    background: #2C2C2C !important;
}

.bGreen{
    background: #6EBE49 !important;
}

.textbOrange{
    color: #FF5000 !important;
}

.textbYellow{
    color: #F8BE15 !important;
}

.textbGreen{
    color: #6EBE49 !important;
}


.visit-schedule-item{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.visit-schedule-item-header{
    background:#6EBE49;
    border-radius: 25px 25px 0px 0px;
    margin-bottom: 0;

    width: 150px;
    height: 80px;
  
    display: flex;
    justify-content: center;
    align-items: center;
}

.visit-schedule-item-header-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
}

.visit-schedule-item-header-subtitle{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    margin-left: 8%;
    text-transform: uppercase;

}

.visit-schedule-item-body{
    background:#fff;
    border-radius: 0px 0px 25px 25px;
    margin-bottom: 0;
    width: 150px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.visit-schedule-item-body-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #2C2C2C;    
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
}

.visit-schedule-item-body-subtitle{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6EBE49;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
}

.visit-no-schedule{
    font-family: NeueBold;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: rgba(44,44,44, 0.5);
    margin-top: 5%;
}


.visit-schedule-item-mobile{
    width: 92px;
    height: 92px;
    background: #6EBE49;
    border-radius: 15px;
}

.visit-schedule-item-mobile-header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.visit-schedule-item-header-mobile-title{

    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF; 
}

.visit-schedule-item-header-mobile-subtitle{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center; 
    color: #FFFFFF;
    text-transform: uppercase;
}

.visit-schedule-item-body-mobile{
    margin-top: 5%;
}

.visit-schedule-item-body-mobile-title{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: #2C2C2C;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;

}

.visit-schedule-item-body-mobile-subtitle{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #6EBE49;  
}
/* HOME */

/* MINHAS REVENDAS */
.page-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #2C2C2C;
}

.body-minhas-revendas{
    margin-top: 3% !important;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 10%;
}

.seagate-button{
    border: none;
    background: linear-gradient(0deg, #6EBE49, #6EBE49), linear-gradient(180deg, #DBFF76 0%, #6EBE49 100%);
    border-radius: 5px;
    min-height: 42px;
   
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #FFFFFF;
    transition: 0s;
}

.seagate-button:hover{
    background: #4f8f32;
}


.minhas-revendas-card{
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902) !important;
    border-radius: 4px !important;
    margin-top: 3%;
}

.table-container{
    padding-top: 1%;
    margin-left: 1%;
    margin-right: 1%;
}

@media (max-width: 600px){
    .page-title{
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        /* margin-left: 5%; */
        margin-top: 3%;
        margin-bottom: 7%;
        text-align: center;
        font-size: 30px;
    }

    .body-minhas-revendas{
        margin-left: -3%;
        margin-right: -3%;
        margin-bottom: 0%;
    }

    .minhas-revendas-card{
        border-radius: 35px 35px 0px 0px !important;
    }
}



.loading-text{
    margin-top: 3%;
    font-family: NeueBold;
}

.datatable-icon{
    cursor: pointer;
    transition: 0s;
}

.datatable-icon:hover path{
    fill: #6EBE49 !important
}

.rdt_TableCol{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 17px;
    color: #6EBE49 !important;
}

.rdt_TableCell{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #6C757D;
}

.rdt_TableRow{
    border-bottom: 1px solid #EEF2F7 !important;
}

.rdt_TableHeadRow{
    border-top: 1px solid #EEF2F7 !important; 
    border-bottom: 1px solid #EEF2F7 !important; 
}

.dataTable thead {
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 17px;
    color: #6EBE49 !important;
}

.dataTable tbody {
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #6C757D;
}

.pagination .page-item.active .page-link{
    border-radius: 50% !important;
    background: #6EBE49 !important;
}

tr:nth-child(even) {
    background-color: rgba(198, 198, 198, 0.05)
}

table.dataTable thead .sorting:before, 
table.dataTable thead .sorting_asc:before, 
table.dataTable thead .sorting_desc:before, 
table.dataTable thead .sorting_asc_disabled:before, 
table.dataTable thead .sorting_desc_disabled:before{
    display: none !important;
}

table.dataTable thead .sorting:after, 
table.dataTable thead .sorting_asc:after, 
table.dataTable thead .sorting_desc:after, 
table.dataTable thead .sorting_asc_disabled:after, 
table.dataTable thead .sorting_desc_disabled:after{
    display: none !important;
}

.dataTables_info{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #6C757D;
}

.mdb-datatable-filter{
    margin-right: 20px !important;
}
.mdb-datatable-filter input {
    border: 0;
    border-bottom: 1px solid #C4C4C4;
    outline: none;
    transition: .2s ease-in-out;
    box-sizing: border-box;
    border-radius: 0 !important;
    
}

.mdb-datatable-filter input:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #6EBE49 !important;
    margin-right: 40px !important;
}

.mdb-datatable-filter input[type='text'] {
    background-image: url('./../images/input-search-icon.svg');
    background-position: 95% 6px;
    background-repeat: no-repeat;
}

.dataTables_length.bs-select{
    display: none !important;
}

.datatable-title{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #6C757D;
}

.datatable-subtitle{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
}

.dataTable tbody tr td{
    vertical-align:middle;
}

.datatable-segment::first-letter{
    text-transform: uppercase !important;
}

tbody, td, tfoot, th, thead, tr{
    border-color: none !important;
    border-style: none !important;
}

.dataTable thead tr {
    border-bottom: 1px solid #EEF2F7 !important;
    border-top: 1px solid #EEF2F7 !important;
}


.dataTable tbody tr {
    border-bottom: 1px solid #EEF2F7 !important;
    border-top: 1px solid #EEF2F7 !important;
}

.mdb-datatable-filter{
    margin-bottom: 5%;
}

div.dataTables_wrapper div.dataTables_paginate{
    text-align: right !important;
}

.sr-only{
    display: none;
}


.nova-revenda-form{
    margin-left: 5% !important;
    margin-right: 5% !important;
    padding-top: 3% !important;
    padding-bottom: 3% !important;
}

@media (max-width: 600px){
    .nova-revenda-form{
        margin-left: 8% !important;
        margin-right: 8% !important;
        padding-top: 5% !important;
        padding-bottom: 3% !important;
    }
}

.form-label-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
}

.add-contact-item{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6EBE49;
    cursor: pointer;
    margin-top: 2%;
}

.form-backbutton{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #98A6AD;
    display: flex;
    align-items: center;
    height: 100%;
}

.schedule-drawer-container{
    min-width: 30vw !important;
}


.drawer-close-icon{
    cursor: pointer;
    margin-top: 20px;
}

.drawer-title{  
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #6D6D6D;
}
.drawer-subtitle{  
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49 !important;
}

.drawer-title-content{
    margin-top: 5%;
}

.drawer-title-content{
    transition: 0s !important;
}

.drawer-divider{
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);
    margin-top: 5%;
    /* margin-left: 5%; */
    /* margin-right: 5%; */
}

.drawer-address-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    color: #6EBE49;
}

.drawer-address-subtitle{
    font-family: Neue;
    margin-top: 3%;
}

.drawer-address-subtitle p::first-letter{
    text-transform: uppercase !important;
}

.drawer-address-subtitle2{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: #6D6D6D;
}

.drawer-address-subtitle2:first-letter{
    text-transform: uppercase !important;
}

.add-contact-item{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #6EBE49;
    cursor: pointer;
    margin-top: 2%;
}

.contact-name{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #2C2C2C;
}

.contact-office{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #6EBE49;
}


.contact-phone{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #2C2C2C;
}

.contact-mail{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    color: #2C2C2C;
}



.seagate-button{
    border: none;
    background: linear-gradient(0deg, #6EBE49, #6EBE49), linear-gradient(180deg, #DBFF76 0%, #6EBE49 100%);
    border-radius: 5px;
    min-height: 42px;
   
    white-space: nowrap;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #FFFFFF;
    transition: 0s;
}

.seagate-button-mobile{
    border: none;
    background: linear-gradient(0deg, #6EBE49, #6EBE49), linear-gradient(180deg, #DBFF76 0%, #6EBE49 100%);
    border-radius: 5px;
    min-height: 42px;
   
    white-space: nowrap;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #FFFFFF;
    transition: 0s;
    width: 100% !important;
}

.seagate-button:hover{
    background: #4f8f32;
}


.seagate-button:hover{
    background: #4f8f32;
}

.seagate-button:disabled{
    background: #656565;
}

.margin-button-drawer{
    width: 80% !important;
    margin-top: 20px;
    margin-bottom: 20px;
}

.mobile-svg-input-search{
    /* position: relative; */
    padding-left: 45%;
}

.mobile-card{
    transition: 0.0001s !important;
    background: #fff;
    margin-top: 5%;
    border-radius: 12px 12px 0px 0px;
    min-height: 95vh;
}

.mobile-card-content{
    transition: 0.0001s !important;
    padding-top: 6%;
    /* padding-left: 10% !important;
    padding-right: 10% !important; */
    padding-bottom: 25% !important;

    padding-left: 5% !important;
    padding-right: 5% !important;
}

.mobile-reseller-list{
    min-height: 50px;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* margin-left: -10px; */
    cursor: pointer !important;
    margin-top: 10px !important;
}

.mobile-reseller-subtitle{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #2c2c2c;
    width: 100%;

    max-width: 100vw; 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;

}

.mobile-reseller-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #2C2C2C;
    width: 100%;
    margin-top: 2%;

    max-width: 100vw; 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile-reseller-divider{
    border-bottom: 1px solid #C4C4C4;
    margin-left: -7.5% !important;
    margin-right: -7.5% !important;
    margin-top: 2% !important;
    /* padding-left: -100px !important; */
}

.fixed-bottom-button{
    background: #2C2C2C !important;
    min-height: 77px  !important;
    z-index: 999 !important;
    width: 100% !important;

    position: fixed;
    bottom: 0px;
}

.fixed-bottom-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 77px  !important;
    font-family: NeueBold;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
    width: 100% !important;
}

.mobile-reseller-search{
    width: 100%;
    border: none !important;
    background: #6EBE49;
    color: #fff;
    font-family: Neue;
    padding-left: 2% !important;
    padding-top: 1% !important;
}


.mobile-reseller-search::placeholder{
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;    
    color: rgba(255, 255, 255, 0.5) !important;
}

.mobile-reseller-search-content {
    /* background-image: url('./../../assets/images/input-search-icon-white.svg'); */
    background-position: 15px 2px;
    background-repeat: no-repeat;
}

.search-divider{
    margin-top: 1.5%;
    border: 0.5px solid #FFFFFF;
    margin-left: 7%;
    margin-right: 7%;
}

.row-input-serach-mobile{
    padding-left: 7% !important;
    padding-bottom: 5px;
}

.mobile-svg-input-search{
    /* position: relative; */
    padding-left: 45%;
}

/* .mobile-reseller-item{

} */

.reseller-drawer-container{
    margin-top: 5%;
}

.no-results-found{
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Neue;

    color: #6EBE49;
    font-size: 13pt;
}
.opt-fixed-bottom{
    position: fixed;
    bottom: 0;
    height: 66px;
    background: #2C2C2C;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Arial, Helvetica, sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;

    color: #6EBE49;

}

.rippleList{
    /* margin-right: 15% !important; */
    /* margin-left: 10% !important; */
    margin-top: 1% !important;
}

.mobile-backnav-container{
    transition: 0.0001s !important;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.mobile-backnav-container-fixed{
    transition: 0.0001s !important;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;

    position: fixed;
    top: 3%;
    /* left: 3%; */
    left: 5%;
    z-index: 9001 !important;
}


.backnav-icon{
    margin-top: -1.5%;
    margin-right: 5% !important;
    padding-right: 5% !important;
}

.backnav-button{
    display: flex;
}

.back-navcontainer{
    width: 22px;
    height: 22px;
    background: white;
    border-radius: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-title-new{
    font-family: NeueBold !important;
    font-style: normal !important;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #2C2C2C;
    margin-bottom: 5%;
}

.mobile-reseller-edit-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;    
    color: #2C2C2C;
}

.mobile-reseller-edit-subtitle{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
}
/* MINHAS REVENDAS */

/* MODAL */

.modal-close-icon{
    cursor: pointer;
}

.modal-close-container{
    padding-bottom: 2%;
    padding-top: 1%;
    margin-bottom: 2%;
    border-bottom: 1px solid #DEE2E6;
    margin-left: -6% !important;
    margin-right: -6% !important;
}

.modal-success-container{
    background: #6EBE49 !important;
    padding: 0px !important;
    margin: 0px !important;

    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
}

.modal-success-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #FFFFFF;
}

.modal-success-text{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    color: #6C757D;
}

.modal-success-reseller{
    font-family: NeueBold;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #6EBE49;
}

.modal-success-date{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: #6EBE49;
}

.modal-info{
    background: red;
}

.modal-text{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    color: #6C757D;
    
}

.modal-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    color: #6C757D;
}

.modal-body-message{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    color: #6C757D;
}

.modal-success-button{
    background: #6EBE49;
    border-radius: 2px;
    min-height: 37px;
    padding-right: 10px;
    padding-left: 10px;

    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF; 

    border: none
}

.modal-success-button:hover{
    background: #4f8f32;
}

.modal-success-button:disabled{
    background: rgb(173, 173, 173);
    cursor: not-allowed;
}

.bg-green{
    background: #6EBE49;  
}

.seagate-button-spinner-content{
    width: 100px !important;
    display: flex;
    justify-content: center;
}

.modal-disabled-button{
    background: #efefef;
    border-radius: 2px;
    min-height: 37px;
    padding-right: 10px;
    padding-left: 10px;

    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    text-align: center;
    color: #2C2C2C; 

    border: none
}

.modal-disabled-button:hover{
    background: #aaaaaa;
}

.text-darkGrey{
    color: #4C4C4C
}
/* MODAL */

/* AGENDA */
.body-calendario{
    margin-top: 5%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 10%;
}

@media (max-width: 500px){
    .body-calendario{
        margin-top: -5%;
        margin-left: 0%;
        margin-right: 0%;
    }
}

@media (min-width: 1500px){
    .body-calendario{
        margin-top: 5% !important;
        margin-left: 5% !important;
        margin-right: 5% !important;
    }
}

.w-60{
    min-width: 200px !important;
}

.full-calendar{
    padding: 2% 2% 2% 2%;
    padding-top: 2% !important;
    background: #424242;
    box-shadow: 0px 0px 35px rgba(66, 66, 66, 0.14902);
    border-radius: 4px;
}


.fc-toolbar-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.fc-toolbar-title::first-letter{
    text-transform: uppercase;
}

.fc-button-primary{
    background: #494949 !important; 
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #FFFFFF;
}

.fc-button-primary.fc-button-active{
    background: #6EBE49 !important; 
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 17px;
    color: #FFFFFF;
}

.fc-button-primary::first-letter{
    text-transform: uppercase;
}

.ant-picker-input input{
    color: white !important
}

.fc-timegrid-axis{
    background: #2c2c2c !important;
    color: white !important
}

.fc-day-today{
    background: #333333 !important;
}

.fc-timegrid-slot-label-cushion{
    color: white !important
}

.fc-event-time{
    color: white !important
}

.fc-event-title{
    color: white !important
}

.fc-timegrid-axis-cushion.fc-scrollgrid-shrink-cushion.fc-scrollgrid-sync-inner{
    display: none !important;
}


.fc-col-header-cell{
    background: #494949 !important;
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.fc-col-header tbody{
    border: 1px solid #4E4E4E !important;
}

.fc-theme-standard .fc-scrollgrid{
    border: 1px solid #4E4E4E !important;
}

.fc-theme-standard td{
    border-right: 1px solid #4E4E4E !important;
    border-bottom: 1px solid #4E4E4E !important;
}

.fc-day-other .fc-daygrid-day-number{
    background: #494949;
    border-radius: 50%;
    margin-top: 5px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF !important;
}

.fc-daygrid-day .fc-daygrid-day-number{
    background: #494949;
    border-radius: 100%;
    margin-top: 5px !important;
    margin-right: 5px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF !important;

    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fc-col-header {
    background: #F8FAFC !important;
    height: 40px !important;
}

.fc-col-header tbody tr th .fc-scrollgrid-sync-inner{
    text-transform: uppercase !important;
    margin-top: 6% !important;
    
}

.left-default-avatar{
    width: 60px !important;
    height: 60px !important;
    border: 1px solid #6EBE49;
    background: #6EBE49;
    border-radius: 50%;
}

.first-letter{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    font-family: NeueBold;
    font-size: 20pt;
}

.tTitle{   
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #2C2C2C;
}

.spinnerPosition{
    position: absolute !important;
    z-index: 999999 !important;
    margin-left: 10%;
    margin-top: 0.5%;
}

@media (min-width: 1500px) {
    .spinnerPosition{
        position: absolute !important;
        z-index: 999999 !important;
        margin-left: 8%;
        margin-top: 0.35%;
    }
}

.view-schedule-modal-container{
    width: 500px !important;
}

.rounded-xl{
    border-radius: 10px;
}

.fade.modal.show{
    z-index: 10000 !important;
}
.modal-backdrop{
    z-index: 9999 !important;
}


.fixed-top{
    z-index: 5 !important;
}

.ReactModalPortal{
    z-index: 6 !important;
}

.ReactModal__Overlay{
    z-index: 6 !important;
}

.ReactCrop__crop-selection{
    transition: 0s; 
}

.modal-close-icon{
    cursor: pointer;
}

.modal-close-container{
    padding-bottom: 2%;
    padding-top: 1%;
    margin-bottom: 2%;
    border-bottom: 1px solid #DEE2E6;
    margin-left: -6% !important;
    margin-right: -6% !important;
}


.cross-modal{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    cursor: pointer;
}

.reseller-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    margin-left: 5%;
}

.reseller-weekname{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
}

.reseller-weekdate{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 54px;
    text-align: center;
    color: #FFFFFF;
}

.reseller-monthname{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    
}

.borderRightWhite{
    border-right: 1px solid white;
}

.reseller-address{
    width: 60%;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.reseller-hour{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #39711F;
}


.reseller-time{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF; 
}

.back-reseller-modal{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
}

.overflow-none{
    overflow-x: hidden;
    overflow: hidden;
}

.modalResellerOpt{
    margin-right: 20px;
    cursor: pointer;
}


.newSchedule-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
    margin-top: 2%;
}

.newSchedule-label{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: rgba(44, 44, 44, 0.5);

    display: flex;
    align-items: center;
    height: 100%;
}

.dtPickerInput input{
    border: none;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #6EBE49 !important;
    border-radius: 0px;
    margin-left: -7px !important;
}

.ant-picker-dropdown.ant-picker-dropdown-placement-bottomLeft{
    z-index: 9999999999 !important;
}

.input-time{
    border: 1px solid rgba(80, 80, 80, 0.342);
    padding: 4px !important;
    padding-left: 15px !important;
    max-width: 100px;
}
.MuiFormControlLabel-root{
    margin: 0px !important;
    padding: 0px !important;
    margin-top: -5px !important;
}


.button-seagate-gray{
    background: #EEF2F7;
    border-radius: 2px;

    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    text-align: center;
    color: #6C757D;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
}

.button-seagate-gray:hover{
    background: #cecece;
 
}

.button-seagate-green{
    background: #6EBE49;
    border-radius: 2px;

    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    text-align: center;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    min-width: 100px !important;
}

.f-center{
    display: flex;
    justify-content: center;
}

.button-seagate-green:hover{
    background: #568b3d;
 
}

.button-seagate-green:disabled{
    background: #999898;
    cursor: not-allowed !important;
}

.text-grayseagate{
    color: rgba(44, 44, 44, 0.5)
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{
    z-index: 9999999999 !important;

}

.MuiList-root.MuiMenu-list.MuiList-padding{
    z-index: 99999999999 !important;
}

.zindexselect{
    z-index: 999999999 !important;
}

.MuiPopover-root{
    z-index: 999999999999 !important;
}

.css-2b097c-container{
    border-bottom: 1px dashed rgba(0, 0, 0, 0.38);
}

.text-red{
    color: #f44336;
}
/* AGENDA */

/* CALENDAR */

.react-calendar {
	max-width: 88%;
	background: #2c2c2c;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 0.8em !important;
}

.reactcalendar-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    /* margin: -0.2em !important; */
}
.reactcalendar-container2{
    padding-left: 8%;
    padding-right: 4%;
}
.modal-backdrop
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar__viewContainer {
    max-width: 100% !important;
    margin: 0 auto;
}
/* .react-calendar__month-view {
    height: 300px !important;
} */
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;

}

.react-calendar button:enabled:hover {
    cursor: pointer;
    
}

.react-calendar__navigation {
    height: 50px;
    margin-bottom: 1em;
    border-bottom: 2px solid rgba(228, 228, 228, 0.2);
    
}

.react-calendar__navigation button {
    /* min-width: 44px; */
    background: none;
    color: #ffffff;
    font-size: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;

}

.react-calendar__navigation button[disabled] {
    background-color: transparent;
    
}

.react-calendar__navigation__next-button {
    background: url(./../../assets/images/arrow-calendar.svg) no-repeat !important;
    color: rgba(0, 0, 0, 0) !important;
    left: -15px;
    position: relative;
    top: 5px !important;
    transform: scaleX(-1);
    
}
    
.react-calendar__navigation__prev-button {
    background: url(./../../assets/images/arrow-calendar.svg) no-repeat !important;
    color: rgba(0, 0, 0, 0) !important;
    left: 15px;
    position: relative;
    top: 5px !important;
}

.react-calendar__navigation button {
    display: inline-block;

}

.react-calendar__navigation button:first-letter {
	text-transform: uppercase !important;
}

.react-calendar__navigation__label__labelText .react-calendar__navigation__label__labelText--from:first-letter{
	text-transform: uppercase !important;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    color: #757575 !important;
    
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.8em;
}
    
.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}
    
.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

/* .react-calendar__month-view__weekNumbers .react-calendar__tile { */
    
    /* display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75); */
    
/* } */

.react-calendar__tile {
    color: #fff;
    
}


.react-calendar__month-view__days__day:after {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    color: #f8be15;
    content: '';
    right: -10%;
    width: 100%;
    font-size: 10px;

	display: none;
    opacity: 0;
    position: relative;
    
  
}
    


.react-calendar__month-view__days__day--weekend {
    color: #ffffff;
    
}
    
.react-calendar__month-view__days__day--weekend:after {
    content: ' ';
    color: rgba(0, 0, 0, 0) !important;
    
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
    
}


.react-calendar__month-view__days__day--neighboringMonth:after {
    content: ' ';
    color: rgba(0, 0, 0, 0) !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    
}

.react-calendar__tile {
    text-align: center;
    /* padding: 0.75em 0.5em; */
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif !important;
    border-radius: 100%;
    height: 50px !important;
    width: 50px !important;

}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 100%;
}

.react-calendar__tile--now {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 100%;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #6EBE49 !important;
}
    
.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: rgba(110, 190, 73, 0.8) !important;
    border-radius: 100%;
    color: #ffffff !important;
}
    
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: rgb(110, 190, 73);
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}
    
.react-calendar__navigation__label__labelText--from {
    color: rgb(110, 190, 73);
    top: -10px;
    position: relative;
    line-height: normal;
}


.loading-mobile-calendar{
    position: relative;
    left: 40px;
}

.loading-container{
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;
    z-index: 99999999999 !important;

    background-color: rgba(0, 0, 0, 0.5) !important;

    display: flex;
    justify-content: center;
    align-items: center;
}

.schedule-radius{
    border-radius: 12px !important;
}

.h-100-min{
    min-height: 100vh;
}

.schedule-body{
    min-height: 100vh !important;
    background: #2C2C2C !important;
    border-radius: 12px 12px 0px 0px !important;
}

.monthSelected{
    align-items: flex-start;
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    margin-left: 15px;
    text-align: start;
    color: #FFFFFF;
}

.monthSelected::first-letter{
    text-transform: uppercase;
}

.monthSelectedBorder{
    border-bottom: 2px solid rgba(228, 228, 228, 0.2);
    width: 100%;
    margin-left: -10px !important;
    margin-top: 15px;
    margin-bottom: 15px;
}


.containerSchedules{
    /* border: 1px solid red; */
    /* overflow-y: scroll; */
    height: 100% !important;
    margin-right: 3% !important;
    margin-left: 2.8% !important;
}

.metaSubContent{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;

    height: 100%;
}

.textbGray{
    color: #2C2C2C !important;
}

.textbOrange{
    color: #FF5000 !important;
}

.textbYellow{
    color: #F8BE15 !important;
}

.textbGreen{
    color: #6EBE49 !important;
}


.schedule-box-container{
    display: flex !important;
    justify-content: left !important;
    align-items: flex-start !important;
    flex-direction: column !important;
}

.schedule-box-time{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}



.status_0{
    color: #F8BE15 !important;
}

.status_1{
    color: #66ccff !important;
}

.status_2{
    color: #6EBE49 !important;
}

.status_3{
    color: #DC1F2E !important;
}

.status_4{
    color: #4A4A4A !important;
}

.schedule-box-reseller-name{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin-top: 15px;
    text-align: left !important;
}

.schedule-box-status{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px; 
}

.schedule-box-address{
    margin-top: 10px !important;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);
}

.schedule-box-icon{
    width: 30px !important; 
    height: 30px !important;
}

.schedule-box-divider{
    background: rgba(228, 228, 228, 0.2);
    height: 1px !important;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.schedule-box{
    margin-right: 15px;
    margin-left: 15px;
}

.fixed-button-container{
    position: fixed;
    bottom: 0;
    height: 58px !important;
    width: 100%;
    background-color: #6EBE49;
    z-index: 999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-new-schedule-button{
    background: linear-gradient(0deg, #6EBE49, #6EBE49), linear-gradient(180deg, #DBFF76 0%, #6EBE49 100%);
    border-radius: 5px;
    height: 58px;
    width: 90%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: #2C2C2C;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.actionsvg{
    background-color: #fff !important;
    border-radius: 100% !important;
}

.title-margin{
    margin-top: 11%;
}

.MuiDrawer-root.MuiDrawer-modal{
    z-index: 9999999999999 !important;
}

/* @media (max-width:991px) {
    .MuiDrawer-root.MuiDrawer-modal{
        z-index: 999999 !important;
        background-color: greenyellow;
        outline: 2px solid greenyellow;
        color: greenyellow;
    }
} */
.drawer-back{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
    min-width: 90vw !important;
}


.drawer-schedule-status{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    width: 90vw !important;
}

.drawer-schedule-reseller-name{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    margin-top: 10px!important;
    color: #2C2C2C;
    width: 80vw !important;
}

.drawer-schedule-date{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    margin-top: 5px;
    line-height: 19px;
    color: #6EBE49;
}

.drawer-schedule-finality{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #979797;
}

.first-finality{
    margin-top: 15px!important;
}

.m10{
    margin-top: 10px !important
}

.drawer-divider{
    width: 100% !important;
    height: 1px !important;
    background: rgba(196, 196, 196, 0.5) !important;
    margin-top: 20px;
    margin-bottom: 20px !important;
}

.drawer-schedule-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    color: #6EBE49;
}

.drawer-schedule-address{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    color: #2C2C2C;
}

.drawer-schedule-office{
    color: #6EBE49;
}

.drawer-schedule-button-quest{
    width: 90% !important;

    background: #6EBE49;
    border-radius: 6px;
    height: 50px !important;

    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    font-family: Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
}

.bg-red{
    background: #f44336 !important;
}


.button-seagate-red{
    background: #DC1F2E;
    border-radius: 2px;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14.4px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px !important;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    min-width: 100px !important;
}

.backSup{
    height: 6vh;    
    display: flex;
    align-items: center;
}

.drawer-back2{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    margin-top: 4px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
}

.reseller-drawer-container{
    width: 100vw !important;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
}

.new-schedule-card{
    background: #fff;
    border-radius: 25px 25px 0px 0px;
    margin:0px !important;
    padding: 0px !important;
}

.swal-overlay.swal-overlay--show-modal{
    z-index: 10001 !important;
}
/* CALENDAR */

/* QUESTIONARIO */
.body-questionario{
    margin-top: 5%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 10%;
}

.loading-text{
    margin-top: 2%;
    font-family: NeueBold;
    color: white;
}

.loader__dot {
    animation: 1s blink infinite;
}

.loader__dot:nth-child(2) {
    animation-delay: 250ms;
}

.loader__dot:nth-child(3) {
    animation-delay: 500ms;
}

.quest-message-no-results-title{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
}

.redirect-no-results{
    background: #6EBE49;
    border-radius: 6px !important;
    padding: 1% !important;
    color: #fff;
}

.redirect-no-results:hover{
    color: #fff;
    background: #3f8022;
}

.quest-title-1{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* text-align: center; */
    color: #FFFFFF;
    max-width: 400px;
}

.quest-active-reseller{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    color: #6EBE49;
}

.quest-divider{
    border-bottom: 1px solid  rgba(255, 255, 255, 0.3);
    margin-right: 40% !important;
    margin-left: 40% !important;
    margin-top: 3% !important;
    margin-bottom: 3% !important;
}

.quest-divider-text{
    display: flex;
    justify-content: center;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #6EBE49;
}

.submit-btn{
    font-family: Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;

    min-width: 30vh;
}

.quest-steps-container{
    margin-top: 0%;
    min-height: 100vh;
}

.min-quest-height{
    min-height: 100vh;
}

.quest-desk-back{
    margin-left: 5%;
}

.quest-list{
    margin-top: 10%;
    margin-bottom: 10%;
}

.quest-list-item{
    margin-left: 20%;
    height: 80px !important;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.quest-list-item.active{
    background: linear-gradient(92.36deg, #6EBE49 0%, #DBFF76 100%);
}



.quest-list-item-image{
    display: flex;
    align-items: center;    
    height: 100%;
    justify-content: flex-end;
}

.quest-list-item-image img{
    width: 50%;
}

.quest-list-item-text.active{
    color: #fff !important
}

.quest-list-item-text{
    display: flex;
    align-items: center;
    height: 100%;
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
}

.quest-list-item-text.answered{
    color: rgba(135, 135, 135, 0.679);
}


.fill-green path{
    fill:#6EBE49 !important
}

.card-quest-container{
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

@media (max-width: 500px){
    .body-questionario{
        margin: 0px !important;
        margin-top: 3% !important;
    }

    .card-quest-container{
        min-height: 70vh;
        display: inline;
        /* justify-content: center; */
        /* align-items: center; */
        /* flex-direction: column; */
        padding: 20px !important;
    }

    .input-time{
        border: 1px solid rgba(80, 80, 80, 0.342);
        padding: 4px !important;
        padding-left: 15px !important;
        max-width: 90px;
    }
}

@media (min-width: 1500px){
    .body-questionario{
        margin-top: 1% !important;
        margin-left: 20% !important;
        margin-right: 6% !important;
    }

    .quest-steps-container{
        /* margin-top:-3.5% !important; */
        margin-left: 12% !important;
        min-height: 100vh !important;
    }
  
    .quest-list-item-image img{
        width: 35%;
    }

}


.seagate-button-spinner-contentw{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-reseller-title{
    color:#2C2C2C !important;
    font-family: Neue !important;
    font-size: 13pt !important;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100vw;
}

.mobile-reseller-subtitle{
    font-family: Neue !important;
    color: #fff !important;
    font-size: 10pt !important;
}

.titleQuestCompany{
    font-family: Arial, Helvetica, sans-serif;
    color: #6EBE49 !important;
    font-size: 18pt !important;

    width: 100vw !important; 
    height: 85px;
    white-space: nowrap !important; 
    overflow: hidden !important;
    text-overflow: ellipsis !important;

    background:#2c2c2c;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: bold;
}

.titleQuestCompany2{
    font-family: Arial, Helvetica, sans-serif;
  
    width: 100vw !important; 
    height: 85px;
    background:#2c2c2c;
    
}

.w-30p{
    width: 50% !important;
}
.questTitleType{
    color: #6ebe49;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.subQuest{
    float: right !important;
}

.qTitle{
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90vw;
  text-align: center;
}

.reseller-drawer-container{
    width: 100vw !important;
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
}




.quest-overflow{
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.quest-desk-back{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    color: #6EBE49;
}

.quest-title{
    margin-left: 20%;
    margin-top: 5%;
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 42px;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;
}


.form-label-title-seagate{
    font-family: NeueBold;
    font-size: 16px;
    line-height: 19px;
    color: #2C2C2C;
}

.form-label-text-seagate{
    font-family: Neue !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 19px !important;
    color: #2C2C2C !important;
}

.input-others {
    border: none!important;
    border-bottom: 1px solid #c4c4c4!important;
    border-radius: 0px !important;
}

.input-others2 {
    border: 1px solid #e6e6e6!important;
    border-radius: 4px !important;


}
.w-full {
    width: 100% !important;
}
.block {
    display: block;
}

.border-b-2 {
    border-bottom-width: 2px;
}

.quest-overflow {
    height: 100vh;
    overflow-y: scroll;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.max-w-sm {
    max-width: 26rem;
}

.mb-2 {
    margin-bottom: 0.5rem!important;
    margin-top: 1.0rem!important;
    /* margin-top: ; */
}

.max-w-sm {
    max-width: 24rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important;
}

/* .mb-4 { */
    /* padding-bottom: 0.9rem !important; */
/* } */

.mt-4 {
    padding-top: 0.9rem !important;
}

.justify-start {
    justify-content: flex-start;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.ml-3 {
    margin-left: 3px !important;
}
.mr-3 {
    margin-right: 3px !important;
}
.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important;
}

.footer-actions-container, .footer-actions-container-start {
    display: flex!important;
    align-items: center!important;
    height: 100%!important;
    flex-direction: row!important;
}
.footer-actions-container {
    justify-content: center!important;
}

.w-12 {
    width: 3rem;
}
.h-12 {
    height: 3rem;
}

.rounded-full{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.footer-actions-text{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #6EBE49;
    margin-right: 2%;
    cursor: pointer;
}

.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.rc-slider{
    margin-top: 20px;
}

.text-darkGrey {
    --tw-text-opacity: 1;
    color: rgba(44,44,44,var(--tw-text-opacity));
}
.mb-1 {
    margin-bottom: .25rem;
}
.text-lg, .text-xl {
    line-height: 1.75rem;
}
.text-lg {
    font-size: 1.125rem;
}

.font-bold {
    font-weight: 700;
}
.mb-1 {
    margin-bottom: .25rem!important;
}
.footer-actions-container{
    display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    flex-direction: row !important;
}

.footer-actions-container-start{
    display: flex !important; 
    justify-content: flex-start !important;
    align-items: center !important;
    height: 100% !important;
    flex-direction: row !important;
}

.footer-actions-container-end{
    display: flex !important; 
    justify-content: flex-end !important;
    align-items: center !important;
    height: 100% !important;
    flex-direction: row !important;
}

.mt-20 {
    padding-top: 5rem !important;
}
/* .mb-4 { */
    /* padding-bottom: 2rem !important; */
/* } */

.text-xl {
    font-size: 1.25rem;
}

.pb-6 {
    padding-bottom: 1.5rem;
}
.mb-6 {
    padding-bottom: 1.5rem;
}

.mt-4 {
    margin-top: 2rem !important;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}
[type=button], [type=submit], button {
    -webkit-appearance: button;
}
.bg-green {
    background: #6ebe49!important;
}
.w-full {
    width: 100%;
}
.text-white {
    --tw-text-opacity: 1;
    color: rgba(255,255,255,var(--tw-text-opacity));
}
.text-center {
    text-align: center;
}
.py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
}
/* .mb-4 { */
    /* margin-bottom: 1rem; */
/* } */
.text-xl {
    font-size: 1.25rem;
}
.text-lg, .text-xl {
    line-height: 1.75rem;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.flex {
    display: flex;
}
.rounded {
    border-radius: .25rem;
}
.bg-green {
    --tw-bg-opacity: 1;
    background-color: rgba(110,190,73,var(--tw-bg-opacity));
}
.rounded {
    border-radius: .25rem!important;
}
.text-white {
    color: #fff!important;
}
.text-center {
    text-align: center!important;
}
.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important;
}

button {
    cursor: pointer;
}
button, input, optgroup, select, textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
}
button {
    cursor: pointer;
}
button {
    background-color: transparent;
    background-image: none;
}
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button {
    border: none!important;
}
button, select {
    text-transform: none;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}
/* [role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
} */
button, select {
    text-transform: none;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
button {
    border-radius: 0;
}

.bg-t{
    background-color: #6F6F6F;
}

.bg-darkGrey {
    background: #2C2C2C;
}

.grid-cols-3 {
    grid-template-columns: repeat(3,minmax(0,1fr));
}
.grid-flow-row {
    grid-auto-flow: row;
}
.gap-y-3 {
    row-gap: .75rem;
}
.gap-x-3 {
    -webkit-column-gap: .75rem;
    -moz-column-gap: .75rem;
    column-gap: .75rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.grid {
    display: grid;
}
.rounded-25 {
    border-radius: 25px;
}
.bg-c4c4c4, .bg-t {
    --tw-bg-opacity: 1;
}
.bg-c4c4c4 {
    background-color: rgba(196,196,196,var(--tw-bg-opacity));
}
.h-24 {
    height: 6rem;
}
.bg-orange {
    background: #FF5000;
}
.py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important;
}
.grid-cols-2 {
    grid-template-columns: repeat(2,minmax(0,1fr));
}
.grid-flow-row {
    grid-auto-flow: row;
}
.gap-y-5 {
    row-gap: 1.25rem;
}
.gap-x-3 {
    -webkit-column-gap: .75rem;
    -moz-column-gap: .75rem;
    column-gap: .75rem;
}
.text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
}
.grid {
    display: grid;
}
ol, ul {
    list-style: none;
}
fieldset, ol, ul {
    margin: 0;
    padding: 0;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1em;
}
dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}
ol, ul {
    padding-left: 2rem;
}

.tracking-wider {
    letter-spacing: .05em;
}
.font-bold {
    font-weight: 700;
}
.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.flex-col {
    flex-direction: column;
}
.flex {
    display: flex;
}

.negativeMargin{
    margin-left: -25px !important;
    padding-top: 2vh !important;
}

.minHeight100{
    min-height: 100vh !important;
}
/* QUESTIONARIO */

/* METAS */
.body-metas{
    margin-top: 3%;
    /* margin-left: 3%; */
    /* margin-right: 3%; */
    margin-bottom: 10%;
}

@media (max-width: 500px){
    .body-metas{
        /* margin-top: 30%; */
        margin-left: 0%;
        margin-right: 0%;
    }
}

@media (min-width: 1500px){
    .body-metas{
        margin-top: 4% !important;
        margin-left: 10% !important;
        margin-right: 10% !important;
    }
  
}

.metas-left-column{
    border-right: 1px solid #C4C4C4;
    min-height: 70vh;
}

.metas-title-green{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #6EBE49;
}

.metas-title-black{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #2C2C2C;
}

.metas-subtitle-green{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #6EBE49;
}

.metas-align{
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important; 
}

.metas-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2C2C2C;
}

.text-green{
    color: #6EBE49;
}

.text-red{
    color: #DC1F2E
}

.metas-border-bottom{
    border-bottom: 1px solid #C4C4C4;
}

.metas-card{
    height: 75px;
    border-radius: 70px 0px 0px 70px;
}

.metas-card-orange{
    height: 75px;
    background: #FF5000;
    border-radius: 70px 0px 0px 70px;
}

.metas-card-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.metas-card-number{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.metas-card-percentage{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

}

.metas-card-text{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.engaj-container{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-meta-card{
    background: #2C2C2C;
    border-radius: 12px 12px 0px 0px;
    height:10vh;
}

.mobile-meta-card-header{
    height: 100% !important;
    display: flex;
    align-items: center;
    height: 10vh;
    margin-left: 8%;
    margin-top: 5px !important;
}

.mobile-meta-card-header2{
    height: 100% !important;
    display: flex;
    align-items: center;
    height: 10vh;
    margin-right: 8%;
    float: right;
    margin-top: 5px !important;

}

.mobile-metas-title-green{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #6EBE49;
}

.mobile-metas-title-white{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
}

.mobile-metas-subtitle-white{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: right;
    color: #FFFFFF;
}

.mobile-meta-card-white{
    background: #FFFFFF;
    border-radius: 12px 12px 0px 0px;

    min-height: 50vh;
    margin-top: -10%;
}

.mobile-meta-card2{
    background: #2C2C2C;
    border-radius: 12px 12px 0px 0px;
    height: 15vh;
    margin-top: -5%;

}

.mobile-meta-card-white2{
    background: #FFFFFF;
    border-radius: 12px 12px 0px 0px;

    min-height: 40vh;
    margin-top: -18%;
}

.goal-box, .goal-title {
    font-family: Neue;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    height: 35px;
    color: #fff;
    display: flex;
    align-items: center;
}
.goal-title {
    background: #008f98;
    border-radius: 4px 0 0 4px;
    width: 99%;
}


.goal-title-header{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* display: flex; */
    /* align-items: center; */
    color: #FF5000; 
    padding-bottom: 10px !important;
}


.goal-title{
    background: #008F98;
    border-radius: 4px 0px 0px 4px;

    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    height: 35px;
    color: #fff;

    display: flex;
    align-items: center;
    width: 99%;
    
}

.goal-box{
    width: 98%;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    height: 35px;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
}


.marginTopGoals{
    margin-top: 3px !important;
}

.mBoxleft{
    margin-left: 1px !important;
    border-radius: 0px 3px 3px 0px !important;
}

.metaSubContent{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    height: 100%;
}

.ml-auto {
    margin-left: auto;
}

.logout-container{
    background: #2C2C2C;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    margin-top: -5%;
}

.logout-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 36px;
    color: #fff;
    margin-top: 3%;
}

@media (max-width:500px){
    .mobile-reseller-subtitle{
        font-family: Neue;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #2c2c2c !important;
        width: 100%;
    
        max-width: 100vw; 
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    
    }

    .mobile-reseller-subtitle2{
        color: #fff !important;
        margin-bottom: 15px !important;
    }
}
/* METAS */


@media (max-width: 600px) {
    .quest-title-1{
        margin-left: 10% !important;
        margin-top: 10% !important;

        font-family: Arial, Helvetica, sans-serif;
        font-size: 12pt;
        font-weight: normal !important;
        line-height: 25px !important;
    }

    .mobile-reseller-title2{
        color:#69bf4a !important;
        font-family: Neue !important;
        font-size: 13pt !important;
        

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80vw;
    }

    .ripplesQuest{
        /* border-bottom: 2px solid rgba(228, 228, 228, 0.2); */

        margin-left: 10% !important;
        margin-right: 10% !important;
        margin-top: 10% !important;
        cursor: pointer !important;
    }

    .subTextQuest{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
    }
    
    .app-menu-footer{
        background: linear-gradient(176.67deg, #656565 -3.45%, #404040 35.5%, #2C2C2C 100%), #FFFFFF;
    }
}

.check-Title{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #6EBE49;
}

.divider-checks{
    border-bottom: 1px solid #D8D8D8;
}

.checks-sub{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */


    color: #2C2C2C;
    margin-top: 3vh;

}

.h-60{
    height: 60px;
}

.centerItems{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}

.upload-border{
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 3px;
}

.checks-up-image{
    max-height: 40px;
}

.filesize-checks{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    
    color: #868589;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: none;
    max-width: 100%;
}

.filename-checks{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    
    color: #868589;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.images-container{
    margin-left: 0.5%;
    margin-right: 0.5%;
}

.bg-teal{
    background: #008F98;
}

.card-metas{
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;

    min-height: 10vh;

}

.card-metas-container{
    padding: 20px !important
}

.card-metas-title{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 14.4px;
    line-height: 20px;
    /* identical to box height, or 139% */
    text-transform: uppercase;
    color: #2C2C2C;
    text-align: left;
}

.text-yellow{
    color: #F8BE15
}

.metas-chart-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    padding-bottom: 10px;
    position: relative;
}
.card-metas-title + .metas-chart-container::after {
    content: '';
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 180px;
    border-radius: 100px;
    box-shadow: 0px 0px 13px rgb(0 0 0 / 40%);
}

.apexcharts-datalabel-label{
    display: none !important;
}

.apexcharts-datalabel-value{

    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    
    color: #F8BE15;

    margin-top: -200px !important;
    
}


.svg-item {
    width: 100%;
    font-size: 16px;
    margin: 0 auto;
    animation: donutfade 1s;
  }
  
  @keyframes donutfade {
    /* this applies to the whole svg item wrapper */
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
  
  @media (min-width: 992px) {
    .svg-item {
      width: 80%;
    }
  }
  
  .donut-ring {
    stroke: #ebebeb;
  }
  
  .donut-segment {
    transform-origin: center;
    stroke: #ff6200;
  }
  
  .donut-segment-2 {
    stroke: aqua;
    animation: donut1 3s;
  }
  
  .donut-segment-3 {
    stroke: #d9e021;
    animation: donut2 3s;
  }
  
  .donut-segment-4 {
    stroke: #ed1e79;
    animation: donut3 3s;
  }
  
  .segment-1 {
    fill: #ccc;
  }
  .segment-2 {
    fill: aqua;
  }
  .segment-3 {
    fill: #d9e021;
  }
  .segment-4 {
    fill: #ed1e79;
  }
  
  .donut-percent {
    animation: donutfadelong 1s;
  }
  
  @keyframes donutfadelong {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes donut1 {
    0% {
      stroke-dasharray: 0, 100;
    }
    100% {
      stroke-dasharray: 69, 31;
    }
  }
  
  @keyframes donut2 {
    0% {
      stroke-dasharray: 0, 100;
    }
    100% {
      stroke-dasharray: 30, 70;
    }
  }
  
  @keyframes donut3 {
    0% {
      stroke-dasharray: 0, 100;
    }
    100% {
      stroke-dasharray: 1, 99;
    }
  }
  
  .donut-text {
    font-family: Arial, Helvetica, sans-serif;
    fill: #ff6200;
  }
  .donut-text-1 {
    fill: aqua;
  }
  .donut-text-2 {
    fill: #d9e021;
  }
  .donut-text-3 {
    fill: #ed1e79;
  }
  
  .donut-label {
    font-size: 0.28em;
    font-weight: 700;
    line-height: 1;
    fill: #000;
    transform: translateY(0.25em);
  }
  
  .donut-percent {
    font-size: 0.5em;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
  }
  
  .donut-data {
    font-size: 0.12em;
    line-height: 1;
    transform: translateY(0.5em);
    text-align: center;
    text-anchor: middle;
    color: #666;
    fill: #666;
    animation: donutfadelong 1s;
  }
  
  /* ---------- */
  /* just for this presentation */

  .svg-item {
    max-width: 45%;
    display: inline-block;
  }
  



  .donutchart-track{
    fill: transparent;
    stroke: #edeff0;
    stroke-width: 26;
}
.donutchart-indicator {
	fill: transparent;
	stroke: #62440F;
	stroke-width: 26;
	stroke-dasharray: 0 10000;
	/* transition: stroke-dasharray .3s ease; */
	transition: stroke-dashoffset 2s linear;
	stroke-linejoin: round;
	stroke-linecap: round; 

	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}

.donutchart-indicator-red {
	fill: transparent;
	stroke: #ED1C24;
	stroke-width: 26;
	stroke-dasharray: 0 10000;
	transition: stroke-dashoffset 2s linear;
	stroke-linejoin: round;
	stroke-linecap: round; 
	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}

.donutchart-indicator-yellow {
	fill: transparent;
	stroke: #ED801C;
	stroke-width: 26;
	stroke-dasharray: 0 10000;
	transition: stroke-dashoffset 2s linear;
	stroke-linejoin: round;
	stroke-linecap: round; 
	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}

.donutchart-indicator-green {
	fill: transparent;
	stroke: #0DB73C;
	stroke-width: 26;
	stroke-dasharray: 0 10000;
	transition: stroke-dashoffset 2s linear;
	stroke-linejoin: round;
	stroke-linecap: round; 
	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}

@keyframes circle-chart-fill {
	to { stroke-dasharray: 0 3000; }
}
  
  .donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
  }

  .donutchart-text{
    fill: #62440F;
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 50px !important;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    color: #62440F;
  }

  .donutchart-text-val{
	font-size:51px;

  }
  .donutchart-text-percent{
	font-size:40px;
	
  }
  .donutchart-text-label{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 17px;    
    text-align: center;
    text-transform: uppercase;
    color: #000000;  
	width: 120px;
	color: #000 !important;
	fill: #000 !important;

	
    
}

.donut-red{
	fill: #ED1C24;
}

.donut-yellow{
	fill: #ED801C;
}

.donut-green{
	fill: #0DB73C;
}


.gauge-track{
    fill: transparent;
    stroke: #edeff0;
    stroke-width: 26;
}



.gaugechart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}



.gauge-text-val{
    font-size:45px;
}


.gauge-text-label{
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    /* line-height: 17px;     */
    text-align: center;
    text-transform: uppercase;
    color: #000000 !important;  
}



.gauge-text-percent-yellow{
	border: 1px solid #F8BE15;
	box-sizing: border-box;
	border-radius: 6px;
	font-size: 25px;    
}

.gauge-text-yellow{
    fill: #F8BE15;
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 40px !important;
    /* line-height: 25px; */
    text-align: center;
    text-transform: uppercase;
    color: #000 !important;
}

.gauge-indicator-yellow {
	fill: transparent;
	stroke: #F8BE15;
	stroke-width: 26;
	stroke-dasharray: 0 10000;
	transition: stroke-dashoffset 2s linear;
	stroke-linejoin: round;
	stroke-linecap: round; 
	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}

/* --------- */

.gauge-text-percent-red{
	border: 1px solid #f44336;
	box-sizing: border-box;
	border-radius: 6px;
	font-size: 25px;    
}

.gauge-text-red{
    fill: #f44336 !important;
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 40px !important;
    /* line-height: 25px; */
    text-align: center;
    text-transform: uppercase;
    color: #000 !important;
}

.gauge-indicator-red {
	fill: transparent;
	stroke: #f44336;
	stroke-width: 26;
	stroke-dasharray: 0 10000;
	transition: stroke-dashoffset 2s linear;
	stroke-linejoin: round;
	stroke-linecap: round; 
	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}

/* --------- */
.gauge-text-percent-green{
	border: 1px solid #6EBE49;
	box-sizing: border-box;
	border-radius: 6px;
	font-size: 25px;    
}

.gauge-text-green{
    fill: #6EBE49 !important;
    font-family: Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 40px !important;
    /* line-height: 25px; */
    text-align: center;
    text-transform: uppercase;
    color: #000 !important;
}

.gauge-indicator-green {
	fill: transparent;
	stroke: #6EBE49;
	stroke-width: 26;
	stroke-dasharray: 0 10000;
	transition: stroke-dashoffset 2s linear;
	stroke-linejoin: round;
	stroke-linecap: round; 
	animation: circle-chart-fill 2s reverse;
	transform: rotate(-90deg);
	transform-origin: center;
}


.bg-yellow {
    background: #F8BE15;
}

.metas-acoes-f{
    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;

    height: 42px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

}


.acoes-title{
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #2C2C2C;
}

.acoes-goals{
    text-align: center;
    font-family: Neue;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
}

.acoes-goals-bold{
    text-align: center;
    font-family: NeueBold;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
}

.acoes-divider{
    border-bottom: 1px solid #C4C4C4;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 10px;

    width: 97%;
    margin-left: 5%;
}

.percentage-text{
    font-size: 20px;

}

.options-mobile-acoes-container{
    margin-right: 5%;
    margin-left: 5%;
    display: flex;

    flex-direction: row !important;
}

.opt-mobile-acao-item{
    width: 50%;

    height: 36px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: NeueBold;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */

    text-align: center;

}

.opt-mobile-acao-item.unselected{
    color: #868589;
    background: #E6E6E6;
}


.opt-mobile-acao-item.selected{
    color: #FFF;
    background: #6EBE49;
}


.card-mobile-acoes{
    background: #FFFFFF;
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;

    margin-right: 7.8%;
    margin-left: 7.8%;
    margin-top: 5%;
}

.mobile-acoes-container{
    padding: 20px;
}
.swal-button{
    background-color:#6EBE49!important
}
#edit-reseller-schedule{
    position: absolute;
    z-index: 9999;
    overflow-y: auto;
    top: 10vh;
    width: 100%;
}

.fade.modal.show.custom-modal-position{
    z-index: 9999999999999999!important;
    background-color: rgb(0 0 0 / 25%);
}

.invert-to-white{
    filter: invert(1) brightness(1.5);
}


.row.options-drawer.btn_close {
    margin: 15px 0;
}

.px-5percent{
    padding: 0 5%;
}

.body-calendario .fc-toolbar-chunk:nth-child(3) .fc-button-group {
    display: none;
}

.info-new-visit {
    padding-top: 20px;
    padding-bottom: 20px;
}

.css-l2hebr-Input{
    color: #000 !important;
}

.css-10iso4c{
    color: #000 !important;
}




/* SCROLL BAR NEW SCHEDULE */
/* reseller-drawer-container*/


.reseller-drawer-container::-webkit-scrollbar {
    width: 0px;
}
.reseller-drawer-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(12, 10, 10, 0.5); 
    border-radius: 10px;
}

.reseller-drawer-container::-webkit-scrollbar-thumb {
    background: rgba(27, 18, 18, 0.5); 
    border-radius: 10px;
}

.reseller-drawer-container::-webkit-scrollbar-thumb:hover {
    background: #6EBE49; 
    cursor: pointer !important;
}

/* Esqueceu a senha */

/* @media screen and (max-width: 900px) {
    .forgot .login-background-image,
    .recover .login-background-image  {
        display: none;
    }

    .forgot-container,
    .recover-container {
        width: 100vw;
        height: 90vh;
        font-size: 16px;
        margin: auto;
    }
} */

.forgot,
.recover {
    display: flex;
    margin: 0;
    padding: 0;
}

.forgot .login-background-image,
.recover .login-background-image {
    width: 40vw;
}

.forgot-container,
.recover-container {
    width: 40vw;
    height: 90vh;
    margin: auto;
    font-size: 16px;
}

.forgot-seagate-image-container,
.recover-seagate-image-container {
    display: flex;
    align-items: center;
    justify-content: end;
}

.forgot-seagate-image-container img,
.recover-seagate-image-container img {
    height: 60px;
}

.forgot-submit,
.recover-submit {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forgot-texto,
.recover-texto {
    margin-top: 5vh;
}

.forgot-container form,
.recover-container form {
    margin-top: 5vh;
}

.forgot-form-submit-container,
.recover-form-submit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vh;
}

.forgot-submit-button,
.recover-submit-button {
    background: #6EBE49;
    min-height: 60px;
    min-width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 
        0px 3px 5px -1px rgb(0 0 0 / 20%), 
        0px 6px 10px 0px rgb(0 0 0 / 14%), 
        0px 1px 18px 0px rgb(0 0 0 / 12%);
    box-sizing: border-box;
}

.forgot-button-icon,
.recover-button-icon {
    width: 15px;
    margin-left: 5px;
}

.recover-container form div {
    margin: 15px 0px 10px 0px;
}

.feedback-password {
    background-color: #6EBE49;
    color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.feedback-texto {
    margin: 10vh 0vh;
}

.feedback-password a {
   background-color: #fff;
    border-radius: 6px;
    width: 370px;
    height: 50px;
    font-size: 20px;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-btn,
.feedback-btn1:hover {
    color: #0DB73C;
}

.modal-dialog {
  width: 100%;
  /* height: 100%; */
  /* margin: 0; */
  padding: 0;
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

@media screen and (max-width: 768px) {
    .forgot .login-background-image,
    .recover .login-background-image {
        display: none;
    }

    .forgot-container,
    .recover-container {
        width: 95vw;
        height: 100vh;
        margin: auto;
        font-size: 16px;
    }

    .feedback-password a {
        width: 270px;
    }
}